/* BCITO */
import './core/lib/modernizr.custom';

import $ from 'jquery';

import scrollTo from './core/utils/scrollTo.js';
import form from './core/components/form-steps';
import formBuilder from './core/components/form.builder';
import taggedChildSelectorBlock from './core/components/tagged-child-selector-block';
import Reoako from './core/components/reoako';
import youtubeAnalytics from './core/components/youtube-analytics';
import mobileNav from './core/components/mobile-nav';

window.jQuery = $;
window.$ = $;

require('./core/lib.js');

const sentryDsn = 'https://17941333eec34466a3634f190cba76c9@o37692.ingest.sentry.io/1381567';

function externaliseLinks() {
    const links = [].slice.call($('a'));

    if (links.length > 0) {
        links.forEach(it => {
            if (it.host !== location.host && it.host !== '') {
                it.setAttribute('target', '_blank');
                it.setAttribute('rel', 'noopener noreferrer');
            }
        });
    }
}

// This function will allow for the women in trades page story block select to change the visible stories according to the location chosen
function initWomenInTradesStoryFilterByLocation($locations) {
    $('.onload-stories').css('display', 'inline-block');
    $locations.on('change', (e) => {
        const { value } = e.currentTarget;
        if (value === 'select-location') {
            $('.all-stories').hide();
            $('.onload-stories').css('display', 'inline-block');
        } else {
            $('.all-stories').hide();
            $(`.story-in-${ value }`).css('display', 'inline-block');
        }
    });
}

(function (document, window) {
    const Site = function () {
        const self = this;

        if ('addEventListener' in window) {
            document.onreadystatechange = function () {
                if (document.readyState === 'complete') {
                    self.init.call(self);
                }
            };
        } else {
            window.onload = function () {
                self.init.call(self);
            };
        }
    };

    Site.prototype = {

        init() {
            if (sentryDsn) {
                import(/* webpackChunkName: "sentry" */ './modules/sentry.js')
                    .then(({ default: sentry }) => {
                        sentry.init(sentryDsn);
                    });
            }

            mobileNav.init();

            GA.init();
            // Track form steps
            GA.setupTrackables('analytics', 'click', 'button', 'data-label');

            $('.js-popup-video').magnificPopup({
                disableOn: 320,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false,
                callbacks: {
                    open() {
                        youtubeAnalytics.init();
                    },
                },

            });

            $('.js-popup-gmaps').magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false,
            });

            $('.bigLink').bigLink({
                avoid: 'a.noBiglink',
            });

            const workProfileForm = form.init();
            // registerInterestForm.init();

            // Show registration form immediately if this is set:
            if (window.show_form_immediately !== undefined && window.show_form_immediately) {
                workProfileForm.start();
            }

            // Externalise any links to non bcito pages
            externaliseLinks();

            const $locations = $('.location-dropdown').eq(0);
            if ($locations.length === 1) {
                initWomenInTradesStoryFilterByLocation($locations);
            }

            // BCITO Parents sign up
            const signUpParentsBtn = document.getElementById('trade-up-sign-up-parents');
            if (signUpParentsBtn) {
                const parentsForm = document.getElementById('parents-form');
                const parentsFormFirstField = document.getElementById('parents-first-name');
                signUpParentsBtn.addEventListener('click', (e) => {
                    e.preventDefault();
                    scrollTo(parentsForm, -160, 800);
                    parentsFormFirstField.focus();
                });
            }

            taggedChildSelectorBlock.init();
            formBuilder.init();
            Reoako.init();
        },
    };

    const site = new Site();
    window.BCITO = site;
    return site;
}(document, window));
